<template>
  <div class="row justify-content-md-center" :class="{'loading': !loaded}">
    <!-- INICIO Detalhes -->
    <div class="col-md-3 text-left dark-box">
      <div class="row" >
        <div class="row col-12 mb-5" v-if="alertData.Numero_Proposta">
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Número Proposta:</div>
            <div class="col-12 ">{{ alertData.Numero_Proposta }} </div>
          </div>
        </div>

        <div class="row col-12 mb-5" v-if="alertData.Numero_Processo">
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Ref. Royal Cargo:</div>
            <div class="col-12 ">{{ alertData.Numero_Processo }} </div>
          </div>
        </div>

        <div class="row col-12 mb-5">
          <div v-if="alertData.Cliente" class="row col-12 mb-3">
            <div class="col-12 text-muted">Cliente</div>
            <div class="col-12 ">{{ alertData.Cliente }}</div>
          </div>
          <div v-if="alertData.Armador" class="row col-12 mb-3">
            <div class="col-12 text-muted">Armador</div>
            <div class="col-12 ">{{ alertData.Armador }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIM Detalhes -->

    <div class="col-9 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 col-md-12">
          <custom-textarea
            v-model="observacao"
            name="observacao"
            label="Motivo"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            :rows="10"
            v-validate="{ required: true }"
            background="#ffffff"
            :error="errors.first('observacao')">
          </custom-textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-center">
          <button  v-if="alertData.situacao == 2" type="button" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3" @click="onSave()">{{ $t('actions.remove_from_alerts') }}</button>
          <button v-else type="button" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3" @click="onSave()">{{ $t('actions.add_to_alerts') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ReportService from '@/services/ReportService'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

import VeeValidate from 'vee-validate'
import { locale, maxDimensionsRule } from '@/utils/validator'

import 'cropperjs/dist/cropper.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
// Inclusão de cláusula de validação de tamanho máximo para imagem
VeeValidate.Validator.extend('maxdimensions', maxDimensionsRule)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'UpdateAlertStatus',
  props: {
    alertData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loaded: true,
      observacao: null
    }
  },
  components: {
    CustomTextarea
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    onSave (scopeName) {
      let me = this
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          me.loaded = false

          let alertHistoric = {
            idlogisticaHouse: this.alertData.IdLogistica_House,
            observacao: this.observacao,
            situacao: this.alertData.situacao,
            tipo_alerta: this.alertData.tipoAlerta
          }

          ReportService.updateAlertStatus(alertHistoric)
            .then(function (res) {
              if (res) {
                me.$emit('close')
                global.instanceApp.$alertSwal.toast('Dados alterados', 'success')
              }
            })
            .catch(() => {
              me.loaded = true
            })
        }
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>
